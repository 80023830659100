




























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import CardBox from "@/components/card-box/index.vue";
import Pagination from "@/components/pagination/index.vue";
import { PageNum, School } from "@/tool/interface-index";
import { getSchoolCascadeList } from "@/api/school";
import { getExamProjectList } from "@/api/examProject";
import { getExamStudent } from "@/api/examStudent";
import { getExamTimeList } from "@/api/examTime";
import { ExamProject, ExamTime } from "@/tool/interface-index";
import * as _ from "lodash";
import * as CommentApi from "@/api/examComment";
import * as ResApi from "@/api/examFileResource";
import { ExamStudent } from "@/tool/_class";
import BuildExamAnswerPackageDialog from "@/views/exam-management/build-exam-answer-package-dialog.vue";
import {
  CommentParams,
  ExamStudentsStatisticsReport,
  ExamStudentScoreStatistics,
  ExamQuestionScoreStatistics,
} from "@/classes/exam-comment";
import ReScoreEditorDialog from "@/views/statistics-management/components/re-score-editor.vue";
import {
  MessageBoxInputData,
  MessageBoxData,
} from "element-ui/types/message-box";

class QuestionColumn {
  questionId: string = "";
  questionName: string = "";
  questionNo: string = "";
}

interface CascaderExamTime extends ExamTime {
  value: string;
  label: string;
  leaf: boolean;
}
interface CascaderExamProject extends ExamProject {
  value: string;
  label: string;
  leaf: boolean;
  chidren?: CascaderExamTime[];
}

@Component({
  name: "ExamStudentScoreListPage",
  components: {
    CardBox,
    Pagination,
    ReScoreEditorDialog,
    BuildExamAnswerPackageDialog,
  },
})
export default class extends Vue {
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: Array<object> = [];
  private examProjectList: CascaderExamProject[] = [];
  private examTimeList: ExamTime[] = [];
  private query: {
    keyWords: string;
    cascadeExamSchoolId: string[];
    examProjectId: string;
    examTimeId: string;
    examStartTimeWithinDays: number;
  } = {
    cascadeExamSchoolId: [],
    keyWords: "",
    examProjectId: "",
    examTimeId: "",
    examStartTimeWithinDays: 30,
  };

  private schools: any[] = [];
  private items: ExamStudentScoreStatistics[] = [];
  private questions: QuestionColumn[] = [];

  private get audio(): HTMLAudioElement {
    return this.$refs["ansFileAudio"] as HTMLAudioElement;
  }

  private get examType(): string {
    const paths = this.$route.path.split("/");
    const isMockExamMode =
      paths.findIndex((value: string) => {
        return value.toLowerCase() == "mockExamManagement".toLowerCase();
      }) >= 0 ||
      (this.$route.query.examType &&
        this.$route.query.examType == "mockExamMode");
    if (isMockExamMode) {
      return "mockExamMode";
    }
    return "formalExamMode";
  }

  private examProjectCascaderProps = {
    lazy: true,
    lazyLoad: async (node: any, resolve: any) => {
      console.log(`${JSON.stringify(this.examProjectList)}`);
      //this.examProjectList
      //resolve(this.examProjectList)
      resolve([{ label: "test", value: "test" }]);
    },
  };

  private questionScoreFormatter(row: ExamStudentScoreStatistics, column: any) {
    const que = row.examQuestions.find((item: ExamQuestionScoreStatistics) => {
      const sTitle: string = item.questionName || item.questionNo;
      return sTitle == column.label;
    });
    return que ? que.score : "";
  }

  private examStudentsStatistics: ExamStudentsStatisticsReport | undefined =
    undefined;
  private loading: boolean = false;

  handlePlayClick(index: number, row: ExamStudentScoreStatistics) {}

  private async doStartPlayAnswerRecordClick(val: {
    examProjectId: string;
    examTimeId: string;
    examStudentId: string;
    question: ExamQuestionScoreStatistics;
    fileReName?: string;
  }) {
    (this.$refs.reScoreEditorDialog as ReScoreEditorDialog).show(val);
    return;
    console.log(`${JSON.stringify(val)}`);
    try {
      const ansFileName: string = `${val.question.questionNo}ans.mp3`;
      const examProjectId: string = val.examProjectId;
      const examTimeId: string = val.examTimeId;
      const examStudentId: string = val.examStudentId;
      const sAudioUrl: string = `/api/v1/examComment/download/examAnswerFiles`;
      const blob: Blob = await ResApi.downloadAsBlob(sAudioUrl, {
        examProjectId: examProjectId,
        examTimeId: examTimeId,
        examStudentId: examStudentId,
        fileName: ansFileName,
      });
      this.audio.src = URL.createObjectURL(blob);
      this.audio.load();
      this.audio.play();
    } catch (error) {
      //alert(JSON.stringify(error))
    } finally {
      //
    }
  }

  private recipients: string = "";
  async promptRecipients() {
    const promptData: any = await this.$prompt(
      "请输入接收测评结果的邮箱",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: this.recipients,
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "邮箱格式不正确",
      }
    );
    this.recipients = promptData.value;
  }

  private loadingDownload: boolean = false;
  private async doDownloadExamStatisticsReportClick() {
    if (
      this.query.examProjectId == "" ||
      (_.last(this.query.cascadeExamSchoolId) || "") == ""
    ) {
      this.$message({
        message: "请先选择：考点、考试项目。",
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }

    try {
      this.loadingDownload = true;
      const examProjectId = this.query.examProjectId;
      const examSchoolId = _.last(this.query.cascadeExamSchoolId) || "";
      const examTimeId = this.query.examTimeId;
      const fileName = `考生成绩明细[(不含缺考)].xlsx`; //`${row.examProjectName}(${row.examSchoolName}-${row.examTimeName})考生成绩明细[(不含缺考)].xlsx`;
      //const baseUrl = process.env.VUE_APP_BASE_API;
      //const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${row.examProjectId}&examSchoolId=${row.examSchoolId}&examTimeId=${row.examTimeId}&fileName=${fileName}`;
      const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${examProjectId}&examSchoolId=${examSchoolId}&examTimeId=${examTimeId}&fileName=${fileName}`;

      await ResApi.download(`${examStudentsReportShortFileUrl}`, {}, fileName);
    } catch (error) {
      //
    } finally {
      this.loadingDownload = false;
    }
  }

  private loadingSendEmail: boolean = false;
  private async handleSendExamStatisticsReportClick() {
    //参数判断
    if (
      this.query.examProjectId == "" ||
      (_.last(this.query.cascadeExamSchoolId) || "") == ""
    ) {
      this.$message({
        message: "请先选择：考点、考试项目。",
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    //
    await this.promptRecipients();
    //
    try {
      this.loadingSendEmail = true;
      await CommentApi.sendExamStatisticsReport({
        examProjectId: this.query.examProjectId,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
        examTimeId: this.query.examTimeId,
        recipients: this.recipients,
      });
      this.$message({
        message: `邮件已发送至：${this.recipients}。`,
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } finally {
      this.loadingSendEmail = false;
    }
  }

  private queryStudentScores() {
    /*
    if (this.query.examProjectId == "") {
      this.$message({
        message: "请先选择考试项目。",
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    */
    this.pageNum.curPage = 1;
    this.getExamStudentListData();
  }

  private async getExamStudentListData() {
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      this.items.splice(0, this.items.length);
      this.questions.splice(0, this.questions.length);
      const { data } = await CommentApi.getExamStudentsReport({
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
        keyWords: this.query.keyWords,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
        examProjectId: this.query.examProjectId,
        examTimeId: this.query.examTimeId,
        examType: this.examType || "mockExamMode",
        examStartTimeWithinDays: this.query.examStartTimeWithinDays,
      });
      this.pageNum.totalPage = data.totalPage;
      this.examStudentsStatistics = data;
      this.examStudentsStatistics!.items.forEach(
        (item: ExamStudentScoreStatistics) => {
          this.items.push(item);
        }
      );
      if (this.items.length > 0) {
        let item = this.items[0];
        const idx = this.items.findIndex((a) => {
          return a.examQuestions.length > item.examQuestions.length;
        });
        if (idx >= 0) {
          item = this.items[idx];
        }
        item.examQuestions.forEach((que: ExamQuestionScoreStatistics) => {
          this.questions.push({
            questionId: que.questionId,
            questionNo: que.questionNo,
            questionName: que.questionName || que.questionNo,
          });
        });
      }
      this.$nextTick(() => {
        (this.$refs.examStudentScoreTableView as any).doLayout();
      });
    } finally {
      this.loading = false;
    }
  }
  private upDataPage() {
    this.getExamStudentListData();
  }

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i]
      }
      if (_.has(cascades[i], 'children')) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }

    }
    */
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.schools);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  handleChangeCascadeExamSchool(cascadeExamSchoolId: any) {
    this.__init_examProjectList();
    this.__init_examTimeList();
  }

  handleChangeExamProject(examProjectId: string) {
    this.__init_examTimeList();
  }

  private async __init_examTimeList() {
    try {
      this.examTimeList.splice(0, this.examTimeList.length);
      if (this.query.examProjectId) {
        const { data } = await getExamTimeList({
          examProjectId: this.query.examProjectId,
          pageSize: 999,
          curPage: 1,
        });
        this.examTimeList = data.items;
      }
    } finally {
      const examTime = this.examTimeList.find((item) => {
        return item.examTimeId == this.query.examTimeId;
      });
      if (!examTime) {
        this.query.examTimeId = "";
      }
    }
  }

  private async __init_examProjectList() {
    try {
      this.examProjectList.splice(0, this.examProjectList.length);
      const res = await getExamProjectList({
        examType: this.examType || "mockExamMode",
        pageSize: 999,
        curPage: 1,
        examStartTimeWithinDays: this.query.examStartTimeWithinDays,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
      });

      this.examProjectList = res.data.items.map((item: any) => {
        return _.merge(item, {
          value: item.examProjectId,
          label: item.examProjectName,
        });
      });
    } finally {
      const examProject = this.examProjectList.find((item) => {
        return item.examProjectId == this.query.examProjectId;
      });
      if (!examProject) {
        this.query.examProjectId = "";
      }
    }
  }

  private async doBuildExamAnswerPackage2Click() {
    this.$prompt("请输入考生Id", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      //inputPattern: "",
      //inputErrorMessage: '邮箱格式不正确'
    }).then((data: MessageBoxData) => {
      getExamStudent((data as MessageBoxInputData).value).then(({ data }) => {
        const examStudent: ExamStudent = data;
        (
          this.$refs
            .buildExamAnswerPackageDialog as BuildExamAnswerPackageDialog
        ).show(examStudent);
      });
    });
  }

  private loadingDownloadExamAnswerPackage: boolean = false;
  private async doDownloadExamAnswerPackageClick(
    a: ExamStudentScoreStatistics
  ) {
    try {
      this.loadingDownloadExamAnswerPackage = true;
      const examProjectId = this.query.examProjectId;
      const examStudentId = a.examStudentId;
      const examTimeId = a.examTimeId || this.query.examTimeId;
      const fileReName = `${a.examAccount}.zip`; //`${row.examProjectName}(${row.examSchoolName}-${row.examTimeName})考生成绩明细[(不含缺考)].xlsx`;
      //const baseUrl = process.env.VUE_APP_BASE_API;
      //const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${row.examProjectId}&examSchoolId=${row.examSchoolId}&examTimeId=${row.examTimeId}&fileName=${fileName}`;
      const examStudentsReportShortFileUrl = `/api/v1/examComment/download/examAnswerPackageFiles?examProjectId=${examProjectId}&examStudentId=${examStudentId}&examTimeId=${examTimeId}&fileName=${fileReName}`;

      await ResApi.download(
        `${examStudentsReportShortFileUrl}`,
        {},
        fileReName
      );
    } catch (error) {
      //
    } finally {
      this.loadingDownloadExamAnswerPackage = false;
    }
  }

  private doBuildExamAnswerPackageClick(a: ExamStudentScoreStatistics) {
    getExamStudent(a.examStudentId).then(({ data }) => {
      const examStudent: ExamStudent = data;
      (
        this.$refs.buildExamAnswerPackageDialog as BuildExamAnswerPackageDialog
      ).show(examStudent);
    });
  }

  private __init() {
    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
    this.__init_examProjectList();
  }
  mounted() {
    this.query.examProjectId =
      (this.$route.query.examProjectId as string) || "";
    this.query.examTimeId = (this.$route.query.examTimeId as string) || "";
    this.__init_examTimeList();
    this.__init();

    this.getExamStudentListData();
  }
  activated() {
    this.getExamStudentListData();
  }
}
