












































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ExamStudent } from "@/tool/_class";
import { getExamRoomList } from "@/api/examRoom";
import { getExamTimeList } from "@/api/examTime";
import { getSchoolList } from "@/api/school";
import { getExamPaperList } from "@/api/examPaper";
import { getExamProjectList } from "@/api/examProject";
import { getExamAnswerRecordList } from "@/api/examAnswerRecord";
import {
  CommentParams,
  ExamStudentsStatisticsReport,
  ExamStudentScoreStatistics,
  ExamQuestionScoreStatistics,
} from "@/classes/exam-comment";

import * as _ from "lodash";
import * as CommentApi from "@/api/examComment";
import * as ResApi from "@/api/examFileResource";

class Params {
  examProjectId: string = "";
  examTimeId: string = "";
  examStudentId: string = "";
  question!: ExamQuestionScoreStatistics;
  fileReName?: string;
}

class MyFormData {
  examProjectId: string = "";
  examTimeId: string = "";
  examStudentId: string = "";
  question!: ExamQuestionScoreStatistics;
}

@Component({
  name: "ReScoreEditorDialog",
  components: {},
})
export default class extends Vue {
  @Prop({ default: () => new Params() }) private params!: Params;
  @Emit("handleConfirmed") private handleConfirmed() {}

  private formData: MyFormData = new MyFormData();
  private fileList: /*{ name: string; url: string }*/ any[] = [];

  private dialogVisible: boolean = false;

  private rules = {
    examTimeId: [
      {
        required: true,
        message: "请选择场次",
        trigger: "blur",
      },
    ],
    examPaperId: [
      {
        required: true,
        message: "请选择试卷",
        trigger: "blur",
      },
    ],
    examRoomId: [
      {
        required: true,
        message: "请选择考场",
        trigger: "blur",
      },
    ],
  };
  private audioTitle: string = "";
  private get audio(): HTMLAudioElement {
    return this.$refs.ansFileAudio as HTMLAudioElement;
  }

  private async __init(params: Params) {
    const ansFileName: string = `${params.question.questionNo}ans.mp3`;
    const examProjectId: string = params.examProjectId;
    const examTimeId: string = params.examTimeId;
    const examStudentId: string = params.examStudentId;
    const sAudioUrl: string = `${process.env.VUE_APP_BASE_API}/v1/examComment/download/examAnswerFiles`;
    const fileReName: string = params.fileReName ? `${params.fileReName}.mp3` : "";
    const blob: Blob = await ResApi.downloadAsBlob(
      sAudioUrl,
      {
        examProjectId: examProjectId,
        examTimeId: examTimeId,
        examStudentId: examStudentId,
        fileName: ansFileName,
        fileReName: params.fileReName || "",
      },
      { type: "audio/mp3" }
    );

    //const audio = this.$refs.ansFileAudio as HTMLAudioElement;
   this.audioTitle = fileReName;
    this.audio.src = URL.createObjectURL(blob);
    this.audio.load();
    this.audio.play();
    return;
    /*
    const resps: any[] = await Promise.all([
      getExamProjectList({
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 1,
      }),
      getExamPaperList({
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 999,
      }),
      getExamTimeList({
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 999,
      }),
      getSchoolList({
        schoolId: examStudent.examSchoolId,
        curPage: 1,
        pageSize: 1,
      }),
      getExamRoomList({
        schoolId: examStudent.examSchoolId,
        curPage: 1,
        pageSize: 999,
      }),
      getExamAnswerRecordList({
        examStudentId: examStudent.examStudentId,
        examTimeId: examStudent.examTimeId,
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 999,
      }),
    ]);
    this.examPapers = resps[1].data.items;
    this.examTimes = resps[2].data.items;
    this.examRooms = resps[4].data.items;
    this.examAnswerRecords = resps[5].data.items;

    this.formData = new MyFormData();

    this.formData.examProjectId = examStudent.examProjectId;

    if (resps[0].data.items.length == 1) {
      //项目信息
      this.formData.examProjectName = resps[0].data.items[0].examProjectName;
      this.formData.examProjectNameRaw = resps[0].data.items[0].examProjectName;
    }

    this.formData.examSchoolId = examStudent.examSchoolId;
    if (resps[3].data.items.length == 1) {
      //学校信息
      this.formData.examSchoolName = resps[3].data.items[0].schoolName;
      this.formData.examSchoolNameRaw = resps[3].data.items[0].schoolName;
      this.formData.examAreaId = resps[3].data.items[0].areaId;
      this.formData.examAreaName = resps[3].data.items[0].areaName;
      this.formData.examAreaNameRaw = resps[3].data.items[0].areaName;
    }
    this.formData.examTimeId = examStudent.examTimeId;
    this.formData.examRoomId = examStudent.examRoomId;

    this.formData.examStudentId = examStudent.examStudentId;
    this.formData.examAccount = examStudent.examAccount;
    this.formData.examStudentName = examStudent.examStudentName;
    this.formData.examAccountRaw = examStudent.examAccount;
    this.formData.examPaperId = examStudent.examPaperId;
    if (!this.formData.examPaperId) {
      this.examAnswerRecords.findIndex((item) => {
        let result: boolean = false;
        result = item.examPaperId !== "";
        if (result) {
          this.formData.examPaperId = item.examPaperId;
        }
        return result;
      });
    }
    */
  }

  private handleOpen() {}

  private handleClose() {
    this.audio.pause();
  }

  async show(params: Params) {
    try {
      this.dialogVisible = true;
      await this.__init(params);
    } finally {
      //
    }
  }
  private async validate() {
    await (this.$refs.reScoreEditorDialogForm as any).validate();
    //
    /*
    this.examTimes.findIndex((item) => {
      let result = item.examTimeId == this.formData.examTimeId;
      if (result) {
        this.formData.examTimeName = item.examTimeName;
        this.formData.examTimeNameRaw = item.examTimeName;
      }
      return result;
    });
    //
    this.examPapers.findIndex((item) => {
      let result = item.examPaperId == this.formData.examPaperId;
      if (result) {
        //
      }
      return result;
    });
    //
    this.examRooms.findIndex((item) => {
      let result = item.examRoomId == this.formData.examRoomId;
      if (result) {
        this.formData.examRoomName = item.examRoomName;
        this.formData.examRoomNameRaw = item.examRoomName;
      }
      return result;
    });
    */
  }
  private async doOkClick() {
    await this.validate();
    //
    //await buildExamAnswerPackage(this.formData);
    //
    this.handleConfirmed();
    this.dialogVisible = false;
  }
}
